import React from "react"
import {graphql} from "gatsby"
import parse from 'html-react-parser';
import SiteContainer from "./site-container";
import {Helmet} from "react-helmet";
import PostContent from "./PostContent";
import SignupCallout from "./SignupCallout";

export default function BlogPost({ postdata, data }) {
    const post = postdata ?? data.allWpPost.edges[0].node
    const fullHead = parse(post.seo.fullHead);

    return (
        <SiteContainer>
            <Helmet>
                <title>{post.seo.title}</title>
                <link rel='stylesheet' id='wp-block-library-css'  href='https://wordpress.firstpartyhq.com/wp-includes/css/dist/block-library/style.min.css?ver=5.8' type='text/css' media='all' />
                { fullHead }
            </Helmet>
            <PostContent post={post} />
            <SignupCallout />
        </SiteContainer>
    )
}
export const query = graphql`
  query($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          content
          date
          author {
              node {
                avatar {
                  default
                  extraAttr
                  forceDefault
                  foundAvatar
                  height
                  rating
                  scheme
                  size
                  url
                  width
                }
                name
              }
            }
          seo {
            fullHead
            title
          }
        }
      }
    }
  }
`